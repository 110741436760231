body {
	margin: 0;
	font-family: 'Sofia Pro', sans-serif !important;
	font-weight: 400 !important;
	font-style: normal !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 20px !important;
	line-height: 32px;
	letter-spacing: 0.02em;
	transition: all 0.2s;
}

.ui.segment {
	margin: 0 !important;
}

.content-container {
	margin: auto 15px;
}

@media only screen and (min-width: 860px) {
	.content-container {
		margin: 0 auto;
		width: 808px;
	}
}
