.mt-0 {
	margin-top: 0px !important;
}

.mb-0 {
	margin-bottom: 0px !important;
}

.mb-60 {
	margin-bottom: 60px !important;
}
.mt-60 {
	margin-top: 60px !important;
}
.ml-5 {
	margin-left: 5px !important;
}
.mt-40 {
	margin-top: 40px !important;
}
.mb-40 {
	margin-bottom: 40px !important;
}
.mt-15 {
	margin-top: 15px !important;
}
.mb-15 {
	margin-bottom: 15px !important;
}
.mt-50 {
	margin-top: 50px !important;
}
.ml-25 {
	margin-left: 25px !important;
}
.mr-25 {
	margin-right: 25px !important;
}
.mb-50 {
	margin-bottom: 50px !important;
}

.mt-88 {
	margin-top: 88px !important;
}

.mb-88 {
	margin-bottom: 88px !important;
}

.mt-80 {
	margin-top: 80px !important;
}

.mb-80 {
	margin-bottom: 80px !important;
}

.mt-100 {
	margin-top: 100px !important;
}

.mb-100 {
	margin-bottom: 100px !important;
}

.mb-100-40 {
	margin-bottom: 100px !important;
}

.m-100 {
	margin: 100px !important;
}

.mt-120 {
	margin-top: 120px !important;
}

.mb-120 {
	margin-bottom: 120px !important;
}

.mt-200 {
	margin-top: 200px !important;
}

.mb-200 {
	margin-bottom: 200px !important;
}

.mt-250 {
	margin-top: 250px !important;
}

.mt-160 {
	margin-top: 160px !important;
}

.mb-160 {
	margin-bottom: 160px !important;
}

.mt-15 {
	margin-top: 15px !important;
}

.mb-15 {
	margin-bottom: 15px;
}

.mt-30 {
	margin-top: 30px !important;
}

.mb-30 {
	margin-bottom: 30px !important;
}

.mt-160-140 {
	margin-top: 160px !important;
}

.mb-160-40 {
	margin-bottom: 160px !important;
}

.mb-40-30 {
	margin-bottom: 40px !important;
}

.mt-40-30 {
	margin-top: 40px !important;
}

.mt-80-40 {
	margin-top: 80px !important;
}

.mb-80-40 {
	margin-bottom: 80px !important;
}

.mb-0-40 {
	margin-bottom: 0px !important;
}

.mt-88-70 {
	margin-top: 88px !important;
}

.mb-88-70 {
	margin-bottom: 88px !important;
}

.mt-20 {
	margin-top: 20px !important;
}

.mb-20 {
	margin-bottom: 20px !important;
}

.mt-70-40 {
	margin-top: 70px !important;
}
.mt-100-40 {
	margin-top: 100px !important;
}

.mt-100-0 {
	margin-top: 100px;
}

.mt-50-0 {
	margin-top: 50px;
}

.mt-80-0 {
	margin-top: 80px !important;
}

.mb-130-50 {
	margin-bottom: 130px !important;
}

.ml-15 {
	margin-left: 15px !important;
}

.mr-15 {
	margin-right: 15px !important;
}

.mt-140 {
	margin-top: 140px !important;
}

.mt-260-140 {
	margin-top: 260px !important;
}

.mt-260-190 {
	margin-top: 260px !important;
}

.mt-120-140 {
	margin-top: 120px !important;
}

.mb-260-120 {
	margin-bottom: 260px !important;
}

.mt-220-180 {
	margin-top: 220px !important;
}

.mb-220-120 {
	margin-bottom: 220px !important;
}

.mt-25 {
	margin-top: 25px !important;
}

.mb-25 {
	margin-bottom: 25px !important;
}

.bold {
	font-weight: bold;
}

.center {
	text-align: center !important;
}

.z-index-1 {
	z-index: 1;
}

h1 {
	font-family: 'Sofia Pro', sans-serif !important;
	font-size: 60px !important;
	font-weight: 400;
	line-height: 60px !important;
	color: #000 !important;
	margin-bottom: 40px !important;
	letter-spacing: -0.035em;
}
h2 {
	font-family: 'Sofia Pro', sans-serif !important;
	font-size: 36px !important;
	line-height: 40px !important;
	font-weight: 400;
	color: #000;
	letter-spacing: -0.02em;
}
h3 {
	font-family: 'Sofia Pro', sans-serif !important;
	font-size: 30px !important;
	font-weight: 400;
}
h4 {
	font-family: 'Sofia Pro', sans-serif !important;
	font-size: 25px !important;
	font-weight: 400;
}
h5 {
	font-family: 'Sofia Pro', sans-serif !important;
	font-size: 20px !important;
	font-weight: 400;
}

p,
.ui.menu .item {
	font-family: 'Sofia Pro', sans-serif !important;
	font-size: 20px !important;
	font-weight: 300;
	line-height: 32px;
	letter-spacing: 0em;
}

p b {
	font-weight: 700 !important;
}

p i {
	font-weight: 300; 
}

ul li {
	font-weight: 300;
	letter-spacing: 0em;
}
ol li {
	font-weight: 300;
	letter-spacing: 0em;
}

.p-text {
	font-family: 'Sofia Pro', sans-serif !important;
	font-size: 20px !important;
	font-weight: 300;
	line-height: 32px;
	letter-spacing: 0em;
}

.p-question {
	font-family: 'Sofia Pro', sans-serif !important;
	font-size: 20px !important;
	font-weight: 400 !important;
	line-height: 32px;
	letter-spacing: 0.02em;
}

.p-bold {
	font-family: 'Sofia Pro', sans-serif !important;
	font-size: 20px !important;
	font-weight: 700 !important;
	line-height: 32px;
	letter-spacing: 0em;
}

.p-italic {
	font-family: 'Sofia Pro', sans-serif !important;
	font-size: 20px !important;
	font-style: italic;
	font-weight: 300;
	line-height: 32px;
	letter-spacing: 0em;
}

.p-link {
	font-family: 'Sofia Pro', sans-serif !important;
	font-weight: 400;
	font-size: 20px;
	line-height: 32px;
	text-decoration-line: underline;
}

/* .p-link:hover {
	text-decoration-line: underline;
} */

.link-black-menu {
	color: #000 !important;
}

.link-black-menu:hover {
	color: #46484d !important;
	transition: all 200ms ease-in-out;
	transition-delay: 40ms;
}

.link-about {
	color: #000 !important;
}

.link-about:hover {
	color: #2973DD !important;
	transition: all 200ms ease-in-out;
	transition-delay: 40ms;
}

.link-black {
	color: #000 !important;
	text-decoration: underline;
}

.link-black:hover {
	color: #46484d !important;
	text-decoration: underline;
	transition: all 200ms ease-in-out;
	transition-delay: 40ms;
}

.link-blue {
	color: #2973dd !important;
	text-decoration: underline;
}

.link-blue:hover {
	color: #104da5 !important;
	text-decoration: underline;
	transition: all 200ms ease-in-out;
	transition-delay: 40ms;
}

.link-red {
	color: #e4343a !important;
	text-decoration: underline;
}

.link-red:hover {
	color: #b9161c !important;
	text-decoration: underline;
	transition: all 200ms ease-in-out;
	transition-delay: 40ms;
}

.link-green {
	color: #7c861a !important;
	text-decoration: underline;
}

.link-green:hover {
	color: #58600C !important;
	text-decoration: underline;
	transition: all 200ms ease-in-out;
	transition-delay: 40ms;
}

.link-purple {
	color: #d51779 !important;
	text-decoration: underline;
}

.link-purple:hover {
	color: #aa0058 !important;
	text-decoration: underline;
	transition: all 200ms ease-in-out;
	transition-delay: 40ms;
}

.button-text {
	font-family: 'Sofia Pro', sans-serif !important;
	font-weight: 300 !important; /* It's actually 400 in Figma as of Mar 21, 2022, but as pointed out, there's a Figma bug involving font-weight, so keeping it at 300 for consistency, for the time being. */
	font-size: 18px !important;
	line-height: 20px !important;
	color: #000 !important;
}

.indicator-text {
	font-family: 'Sofia Pro', sans-serif !important;
	font-weight: 700;
	font-size: 18px !important;
	line-height: 22px !important;
	letter-spacing: 0.02em;
	text-transform: uppercase;
}

.caption-text {
	font-family: 'Sofia Pro', sans-serif !important;
	font-weight: 400;
	font-size: 18px !important;
	line-height: 24px !important;
	letter-spacing: 0.01em;
}

.radio-text {
	font-family: 'Sofia Pro', sans-serif !important;
	font-weight: 400;
	font-size: 18px !important;
	line-height: 28px !important;
}

.checkbox-text {
	font-family: 'Sofia Pro', sans-serif !important;
	font-weight: 400;
	font-size: 18px !important;
	line-height: 28px !important;
}

.form-frame {
	border: 1px solid #000000 !important;
	box-sizing: border-box;
	border-radius: 2px !important;
	height: 48px;
	padding: 9px 10px 11px !important;
}

.form-title {
	font-family: 'Sofia Pro', sans-serif !important;
	font-size: 14px !important;
	font-weight: 700 !important;
	line-height: 22px !important;
	letter-spacing: 0.1em;
}

.form-label {
	font-family: 'Sofia Pro', sans-serif !important;
	font-weight: 700 !important;
	font-size: 14px !important;
	line-height: 16px;
	letter-spacing: 0.1em;
	text-transform: uppercase !important;
}

.form-frame-error {
	border: 1px solid #e0543b !important;
	box-sizing: border-box;
	border-radius: 2px !important;
	height: 48px;
	padding: 9px 10px 11px !important;
}

.form-error-text {
	font-family: 'Sofia Pro', sans-serif !important;
	font-size: 18px !important;
	font-weight: 400 !important;
	line-height: 28px !important;
	letter-spacing: 0em;
	color: #e0543b !important;
}

.fa,
.far,
.fas {
	font-family: 'Font Awesome 5 Free' !important;
}

i.icon {
	font-family: Icons !important;
}

/* * {
	font-family: 'Sofia Pro', sans-serif !important;
	font-size: 20px !important;
	font-weight: 400;
	line-height: 32px;
	letter-spacing: 0.02em;
} */

.font-search {
	font-family: 'Sofia Pro', sans-serif !important;
	font-weight: 400;
}

.color-primary {
	color: #aac7f2 !important;
}

.color-primary:hover {
	color: #2973dd !important;
	transition: 200ms ease cubic-bezier(0, 0.25, 0.5, 1);
	transition-delay: 40ms;
}

.text-blue-500 {
	color: #2973dd !important;
}

.text-red-500 {
	color: #e4343a !important;
}

.text-green-700 {
	color: #7c861a !important;
}

.text-green-700 {
	color: #7c861a !important;
}

.text-purple-500 {
	color: #d51779 !important;
}

.color-danger {
	color: #ff4d53;
}

.color-success {
	color: #bccf00;
}

.color-warning {
	color: #d51779;
}

.color-neutral {
	color: #c6c8cc !important;
}

.color-neutral:hover {
	color: #5f6166 !important;
	transition: 200ms ease cubic-bezier(0, 0.25, 0.5, 1);
	transition-delay: 40ms;
}

.list-right li:before {
	content: '\f105';
}

ul[class*='list'] {
	list-style-type: none;
}

.list-space li {
	margin-top: 10px;
}

[class*='list'] li:before {
	color: #d07430;
	font-family: FontAwesome;
	float: left;
	margin-left: -1.3em;
}

.ui.accordion .accordion .title .dropdown.icon:before,
.ui.accordion .title .dropdown.icon:before {
	content: '\f078';
	font-weight: 900;
	font-family: 'Font Awesome 5 Free';
}

.ui.accordion .accordion .active.title .dropdown.icon,
.ui.accordion .active.title .dropdown.icon {
	transform: rotate(180deg);
}

.icon-dropdown {
	float: right;
}

.h2-dropdown {
	display: inline-block;
	max-width: 85%;
	margin-bottom: 0px; 
}

.border-blue-200 {
	border-top: 1px solid #d7e6fc;
	border-bottom: 0px;
}

.border-neutral {
	border-top: 1px solid #c6c8cc;
	border-bottom: 0px;
}
.border-neutral-100 {
	border-top: 1px solid #e0e2e5;
	border-bottom: 0px;
}
.border-neutral-1000 {
	border-top: 1px solid #000;
	border-bottom: 0px;
}

.border-red-200 {
	border-top: 1px solid #fcd7d8;
	border-bottom: 0px;
}

.border-green-200 {
	border-top: 1px solid #e8edb2;
	border-bottom: 0px;
}

.border-purple-200 {
	border-top: 1px solid #fcd7ea;
	border-bottom: 0px;
}

.border-navy-300 {
	border-top: 1px solid #a3a8cc;
	border-bottom: 0px;
}

#close-icon {
	position: relative;
	top: -20px;
	left: 11px;
}

input[type='radio'] {
	width: 24px;
	height: 24px;
}

input[type='checkbox'] {
	width: 24px;
	height: 24px;
}

input[type='text'] {
	padding-top: 12px !important;
}

input[type='email'] {
	padding-top: 12px !important;
}

.radio-label {
	vertical-align: middle;
}

.radio-button {
	vertical-align: middle;
	margin-right: 8px;
}

.checkbox-label {
	vertical-align: middle;
}

.checkbox-button {
	vertical-align: middle !important;
	margin-right: 8px;
}

.button-neutral {
	display: flex !important;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 14px 24px !important;
	height: 48px !important;
	border: 1px solid #2e3033 !important;
	box-sizing: border-box;
	border-radius: 24px !important;
	background-color: transparent !important;
}

.button-neutral:hover {
	background-color: #e0e2e5 !important;
	transition: all 200ms ease-in-out;
	transition-delay: 40ms;
}

.button-blue {
	display: flex !important;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 14px 24px !important;
	padding-top: 16px !important;
	height: 48px !important;
	box-sizing: border-box;
	border-radius: 24px !important;
	background-color: #d7e6fc !important;
}

.button-blue:hover {
	background-color: #2973dd !important;
	color: #fff !important;
	transition: all 200ms ease-in-out;
	transition-delay: 40ms;
}

.button-red {
	display: flex !important;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 14px 24px !important;
	height: 48px !important;
	box-sizing: border-box;
	border-radius: 24px !important;
	background-color: #fcd7d8 !important;
}

.button-red:hover {
	background-color: #e4343a !important;
	color: #fff !important;
	transition: all 200ms ease-in-out;
	transition-delay: 40ms;
}

.button-green {
	display: flex !important;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 14px 24px !important;
	height: 48px !important;
	box-sizing: border-box;
	border-radius: 24px !important;
	background-color: #e8edb2 !important;
}

.button-green:hover {
	background-color: #bccf00 !important;
	transition: all 200ms ease-in-out;
	transition-delay: 40ms;
}

.button-purple {
	display: flex !important;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 14px 24px !important;
	height: 48px !important;
	box-sizing: border-box;
	border-radius: 24px !important;
	background-color: #fcd7ea !important;
}

.button-purple:hover {
	background-color: #d51779 !important;
	color: #fff !important;
	transition: all 200ms ease-in-out;
	transition-delay: 40ms;
}

.button-gray {
	display: flex !important;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 14px 24px !important;
	height: 48px !important;
	box-sizing: border-box;
	border-radius: 24px !important;
	background-color: #e0e2e5 !important;
}

.button-gray:hover {
	background-color: #919499 !important;
	color: #fff !important;
	transition: all 200ms ease-in-out;
	transition-delay: 40ms;
}

table,
tr.tr-1,
tr.tr-2,
tr.tr-3,
tr.tr-4 {
	border-bottom: 1px solid #d7e6fc;
	border-collapse: collapse;
	table-layout: fixed;
	width: 100%;
}

table,
tr.tr-5 {
	border-bottom: none;
}

th,
td {
	padding-top: 25px !important;
	padding-bottom: 25px !important;
	vertical-align: top;
	text-align: left;
}

.hide {
	display: none !important;
}

.nav {
	--nav-gap: 15px;
	padding: var(--nav-gap);
	position: fixed;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
}

.nav-item {
	align-items: center;
	display: flex;
	flex-direction: row-reverse;
	margin-bottom: var(--nav-gap);
}

.nav-link:hover ~ .nav-label {
	opacity: 1;
}

.nav-label {
	color: #000000;
	font-weight: bold;
	opacity: 0;
	transition: opacity 0.1s;
}

.nav-link {
	background: rgba(0, 0, 0, 0.3);
	border-radius: 50%;
	height: var(--nav-gap);
	margin-left: var(--nav-gap);
	transition: transform 0.1s;
	width: var(--nav-gap);
}

.nav-link-selected {
	background: #000000;
	transform: scale(1.4);
}

@media only screen and (min-width: 501px) and (max-width: 991px) {
	.ui.menu {
		margin: auto 25px !important;
	}
	h3 {
		font-size: 20px !important;
		line-height: 32px !important;
	}
}

@media only screen and (min-width: 501px) {
	.hide-desktop {
		display: none;
	}
	.smaller-logo {
		max-width: 187px !important;
	}
}

@media only screen and (min-width: 768px) {
	.hide-desktop-768 {
		display: none;
	}
}

@media only screen and (max-width: 767px) {
	.hide-mobile-768 {
		display: none;
	}
}

@media only screen and (min-width: 501px) and (max-width: 800px) {
	.header {
		display: flex; 
		flex-wrap: wrap;
	}
	.logo {
		margin: 0 auto !important;
		padding-bottom: 0px !important;
	}
	.right.menu {
		margin: 0 auto !important;
	}
}

@media only screen and (min-width: 380px) and (max-width: 500px)/* (min-width: 473px) and (max-width: 500px) */ {
	.header {
		display: flex; 
		flex-direction: column;
	}
}

@media only screen and (max-width: 500px) {
	p {
		font-size: 19px !important;
		line-height: 28px !important;
	}
	h1 {
		font-size: 48px !important;
		line-height: 48px !important;
	}
	h2 {
		font-size: 32px !important;
		line-height: 36px !important;
	}
	.ui.menu {
		margin: auto 0px !important;
		padding: 0px !important;
	}
	.smaller-logo {
		max-width: 155px !important;
	}
	.mt-160-140 {
		margin-top: 140px !important;
	}
	.mb-160-40 {
		margin-bottom: 40px !important;
	}
	.mb-40-30 {
		margin-bottom: 30px !important;
	}
	.mt-40-30 {
		margin-top: 30px !important;
	}
	.mt-80-40 {
		margin-top: 40px !important;
	}
	.mb-0-40 {
		margin-bottom: 40px !important;
	}
	.mt-88-70 {
		margin-top: 70px !important;
	}
	.mt-70-40 {
		margin-top: 40px !important;
	}
	.mt-100-40 {
		margin-top: 40px !important;
	}
	.mt-80-0 {
		margin-top: 0px !important;
	}
	.mb-130-50 {
		margin-bottom: 50px !important;
	}
	.mb-100-40 {
		margin-bottom: 40px !important;
	}
	.mt-260-140 {
		margin-top: 140px !important;
	}
	.mt-260-190 {
		margin-top: 190px !important;
	}
	.mt-120-140 {
		margin-top: 140px !important;
	}
	.mb-260-120 {
		margin-bottom: 120px !important;
	}
	.mb-80-40 {
		margin-bottom: 40px !important;
	}	
	.ui.input.focus > input,
	.ui.input > input:focus,
	.ui.input.down input,
	.ui.input > input:active,
	.ui.input > input {
		font-size: 24px !important;
		line-height: 24px;
		letter-spacing: -0.01em;
	}
	.hide-mobile {
		display: none;
	}
	.mb-88-70 {
		margin-bottom: 70px !important;
	}
	.mt-220-180 {
		margin-top: 180px !important;
	}
	.mb-220-120 {
		margin-bottom: 120px !important;
	}
	.mt-100-0 {
		margin-top: 0px !important;
	}
	#first-column {
		padding-bottom: 0px !important;
	}
	#second-column {
		padding-top: 0px !important;
	}
	.mt-50-0 {
		margin-top: 0px !important;
	}
	.blue-300 {
		color: #aac7f2;
	}
	.red-300 {
		color: #f48387;
	}
	.green-300 {
		color: #dde58a;
	}
	.purple-300 {
		color: #e58ab9;
	}
	.width-100 {
		width: 100%;
	}
	.header {
		display: flex; 
		flex-wrap: wrap;
	}
	.logo {
		margin: 0 auto !important;
		padding-bottom: 0px !important;
	}
	.right.menu {
		margin: 0 auto !important;
	}
	.about {
		padding-right: 32px !important;
		padding-top: 17px !important;
	}
	.events {
		padding-left: 0px !important;
		padding-right: 32px !important;
		padding-top: 17px !important;
	}
	.icon-search {
		padding-left: 0px !important;
		padding-right: 32px !important;
		padding-top: 17px !important;
	}
	.icon-hamburger {
		padding-left: 0px !important;
		padding-top: 17px !important;
	}
}
