.carousel-numbers .item {
	min-width: 50rem;
	padding-right: 120px;
	position: relative;
	min-height: 400px;
	display: flex;
	align-items: center;
}

.carousel-numbers .item img {
	position: absolute;
	/* top: -45px; */
	min-height: 494px;
	pointer-events: none;
	z-index: -1;
	opacity: 0.5;
}

.inner-carousel-numbers {
	display: flex;
	align-items: center;
}

.carousel-numbers {
	/* padding: 50px 0px 50px;
	padding: 88px 0px 120px; */
	display: flex;
	overflow: hidden;
	margin: 65px 5rem;
	position: relative;
}

.next-carousel-button {
	padding-right: 56px;
	cursor: pointer;
}

@media only screen and (max-width: 500px) {
	.inner-carousel-numbers {
		display: flex;
		align-items: center;
		overflow-x: scroll;
		min-height: 550px;
	}
}

.handle-building {
	border: none;
	border-radius: 1rem;
	flex-grow: 0;
	flex-shrink: 0;
	background-color: rgba(255, 255, 255, .25);
	z-index: 10;
	margin: .25rem 0;
	width: 4rem;
	cursor: pointer;
	font-size: 10rem;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #D51779;
	line-height: 0;
  }
  
.left-handle-building {
border-top-left-radius: 0;
border-bottom-left-radius: 0;
}

.right-handle-building {
border-top-right-radius: 0;
border-bottom-right-radius: 0;
	position: absolute;
	right: 0rem;
	top: 17.5rem;
}

.text-building {
transition: transform 150ms ease-in-out;
}

.handle-building:hover .text-building,
.handle-building:focus .text-building {
transform: scale(1.2);
}

.slider-building {
--items-per-screen: 6;
--slider-index: 0;
display: flex;
flex-grow: 1;
margin: 38px .25rem;
transform: translateX(calc(var(--slider-index) * -8.35%));
transition: transform 250ms ease-in-out;
}

.slider-building > .item {
flex: 0 0 calc(100% / var(--items-per-screen));
max-width: calc(100% / var(--items-per-screen));
aspect-ratio: 25 / 14;
}

.disable-handle-building {
	color: #FCD7EA;
	cursor: auto;
}

.handle-color-building {
	color: #D51779 !important;
}

.disable-handle-color-building {
	color: #FCD7EA !important;
}