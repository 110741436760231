.get-started:hover {
	cursor: pointer;
	color: gray;
	transition: 200ms ease cubic-bezier(0, 0.25, 0.5, 1);
}

.two-buttons {
	justify-content: center;
	display: flex;
}

#mt140-90 {
	margin-top: 140px !important;
}

.mt40 {
	margin-top: 40px !important;
}

@media only screen and (max-width: 320px) {
	#mt140-90 {
		margin-top: 90px !important;
	}
	#mtNegative15Percent {
		margin-top: -20% !important;
	}
}

@media only screen and (min-width: 321px) and (max-width: 1440px) {
	.ui.container {
		margin-top: -6% !important;
	}
	#mt140-90 {
		margin-top: 40px !important;
	}
	.mt40 {
		margin-top: 20px !important;
	}
}

.display-home {
	position: relative; 
	padding-bottom: 20px; 
}