.carousel-fair .item {
	min-width: 37rem;
	padding: 40px 40px 40px 0;
	position: relative;
	min-height: 400px;
	display: flex;
	align-items: center;
}

.carousel-fair .item img {
	position: absolute;
	top: -40px;
	min-height: 494px;
	pointer-events: none;
	z-index: -1;
	opacity: 0.5;
}

.inner-carousel-fair {
	display: flex;
	align-items: center;
}

.carousel-fair {
	padding: 100px 0px 140px;
	padding: 88px 0px 120px;
	display: flex;
	overflow: hidden;
	margin: 0px 5rem;
	position: relative;
}

.next-carousel-button {
	padding-right: 56px;
	cursor: pointer;
}

@media only screen and (max-width: 500px) {
	.inner-carousel-fair {
		display: flex;
		align-items: center;
		overflow-x: scroll;
		min-height: 550px;
	}
}

.handle-aspects {
	border: none;
	border-radius: 1rem;
	flex-grow: 0;
	flex-shrink: 0;
	background-color: rgba(255, 255, 255, .25);
	z-index: 10;
	margin: .25rem 0;
	width: 4rem;
	cursor: pointer;
	font-size: 10rem;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #7C861A;
	line-height: 0;
  }
  
.left-handle-aspects {
border-top-left-radius: 0;
border-bottom-left-radius: 0;
}

.right-handle-aspects {
border-top-right-radius: 0;
border-bottom-right-radius: 0;
	position: absolute;
	right: 0rem;
	top: 21.50rem;
}

.text-aspects {
transition: transform 150ms ease-in-out;
}

.handle-aspects:hover .text-aspects,
.handle-aspects:focus .text-aspects {
transform: scale(1.2);
}

.slider-aspects {
--items-per-screen: 4;
--slider-index: 0;
display: flex;
flex-grow: 1;
margin: 38px .25rem;
transform: translateX(calc(var(--slider-index) * -12.45%));
transition: transform 250ms ease-in-out;
}

.slider-aspects > .item {
flex: 0 0 calc(100% / var(--items-per-screen));
max-width: calc(100% / var(--items-per-screen));
aspect-ratio: 25 / 14;
}

.disable-handle-aspects {
	color: #E8EDB2;
	cursor: auto;
}

.handle-color-aspects {
	color: #7C861A !important;
}

.disable-handle-color-aspects {
	color: #E8EDB2 !important;
}