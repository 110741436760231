@media only screen and (min-width: 768px) {
	.first-question {
		margin-top: 200px !important;
	}
}
@media only screen and (min-width: 501px) and (max-width: 1440px) {
	.ui.container.first-question {
		margin-top: 7% !important;
	}
}
@media only screen and (max-width: 500px) {
	.ui.container.first-question {
		margin-top: 120px !important;
		margin-bottom: 140px !important;
	}
}
.button-yes {
	height: 48px !important;
	left: 213px !important;
	top: 20px !important;
	border-radius: 24px !important;
	padding: 14px, 24px, 14px, 24px !important;
	background-color: #d7e6fc !important;
	color: #000 !important;
	font-size: 18px !important;
	font-style: normal !important;
	font-weight: 400 !important;
	line-height: 20px !important;
	letter-spacing: 0.02em !important;
	text-align: center !important;
}
.button-no {
	height: 48px !important;
	left: 213px !important;
	top: 20px !important;
	border-radius: 24px !important;
	padding: 14px, 24px, 14px, 24px !important;
	background-color: #e0e2e5 !important;
	color: #000 !important;
	font-size: 18px !important;
	font-style: normal !important;
	font-weight: 400 !important;
	line-height: 20px !important;
	letter-spacing: 0.02em !important;
	text-align: center !important;
}
