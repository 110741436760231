.button-section {
	height: 48px !important;
	left: 0px !important;
	top: 120px !important;
	border-radius: 24px !important;
	padding: 14px, 24px, 14px, 24px !important;
	background-color: #d7e6fc !important;
	color: #2973dd !important;
	font-size: 18px !important;
	font-style: normal !important;
	font-weight: 400 !important;
	line-height: 20px !important;
	text-align: center;
}

.carousel .item {
	min-width: 44rem;
	padding: 40px 80px 40px 0;
	position: relative;
}

.carousel .item img {
	position: absolute;
	top: -40px;
	min-height: 494px;
	pointer-events: none;
}

.inner-carousel {
	display: flex;
	align-items: center;
}

.carousel {
	padding: 88px 0px 120px;
	display: flex;
	overflow: hidden;
	margin: 0px 5rem;
	position: relative;
}

.next-carousel-button {
	padding-right: 56px;
	cursor: pointer;
}

@media only screen and (max-width: 500px) {
	.inner-carousel {
		display: flex;
		align-items: center;
		overflow-x: scroll;
		min-height: 550px;
	}
}

.handle {
  border: none;
  border-radius: 1rem;
  flex-grow: 0;
  flex-shrink: 0;
  background-color: rgba(255, 255, 255, .25);
  z-index: 10;
  margin: .25rem 0;
  width: 4rem;
  cursor: pointer;
  font-size: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2973dd;
  line-height: 0;
}

.left-handle {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.right-handle {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
	position: absolute;
	right: 0rem;
	top: 22.3rem;
}

.text {
  transition: transform 150ms ease-in-out;
}

.handle:hover .text,
.handle:focus .text {
  transform: scale(1.2);
}

.slider {
  --items-per-screen: 4;
  --slider-index: 0;
  display: flex;
  flex-grow: 1;
  margin: 0 .25rem;
  transform: translateX(calc(var(--slider-index) * -12.5%));
  transition: transform 250ms ease-in-out;
}

.slider > .item {
  flex: 0 0 calc(100% / var(--items-per-screen));
  max-width: calc(100% / var(--items-per-screen));
  aspect-ratio: 25 / 14;
}

.disable-handle {
	color: #EDF3FC;
	cursor: auto;
}

.handle-color {
	color: #2973dd !important;
}

.disable-handle-color {
	color: #EDF3FC !important;
}