.absolute-bottom {
	position: fixed;
	left: 0;
	bottom: 0px;
	width: 100%;
}

.absolute-bottom-home {
	position: unset;
}

@media only screen and (max-width: 500px) {
	.absolute-bottom {
		left: auto;
		width: 100%;
	}
}

@media only screen and (min-width: 768px) {
	.absolute-bottom-home {
		position: fixed;
	}
}