.hr-line {
	border-bottom: 1px solid #e0e2e5;
	padding-bottom: 40px;
	padding-top: 40px;
}

.display-short-menu {
	height: 90vh ;
    display: flex !important;
    align-items: center ;
    justify-content: center ;
    width: 100% !important;
	min-height: 600px;
	min-width: 345px; 
}

.short-menu-page {
	display: flex;
    height: 600px;
    width: 70%;
    min-width: 1000px;
    justify-content: space-evenly;
    align-items: center;
}

.registry:hover {
	cursor: pointer;
	transition: 200ms ease;
	color: #2973dd !important;
}

.resources:hover {
	cursor: pointer;
	transition: 200ms ease;
	color: #ff4d53 !important;
}

.aspects:hover {
	cursor: pointer;
	transition: 200ms ease;
	color: #bccf00 !important;
}

.building:hover {
	cursor: pointer;
	transition: 200ms ease;
	color: #d51779 !important;
}

.image-container {
	max-width: 392px;
	/* margin-right: 15px;
	margin-left: 15%; */
}

@media only screen and (max-width: 721px) {
	.short-menu-page {
		min-width: 345px;
	}
	.display-short-menu {
		height: 85vh;
	}
	.registry {
		font-weight: 500;
		font-size: 24px !important;
		line-height: 24px !important;
		letter-spacing: -0.02em;
	}
	.resources {
		font-weight: 500;
		font-size: 24px !important;
		line-height: 24px !important;
		letter-spacing: -0.02em;
	}
	.aspects {
		font-weight: 500;
		font-size: 24px !important;
		line-height: 24px !important;
		letter-spacing: -0.02em;
	}
	.building {
		font-weight: 500;
		font-size: 24px !important;
		line-height: 24px !important;
		letter-spacing: -0.02em;
	}
}

@media only screen and (max-width: 375px) {
	.absolute-bottom {
		position: unset;
	}
}

@media only screen and (min-width: 501px) and (max-width: 877px) {
	.blank-picture {
		display: none !important;
	}
}

@media only screen and (max-width: 1220px) {
	.image-container {
		display: none; 
	}
}

#imageRegistry.show, #imageAspects.show, #imageResources.show, #imageBuilding.show {
	visibility: visible;
	opacity: 1;
	-webkit-transition: all 0.5s ease-in-out 0s;
    -moz-transition: all 0.5s ease-in-out 0s;
    -ms-transition: all 0.5s ease-in-out 0s;
    transition: all 0.5s ease-in-out 0s;
}

#imageRegistry.fade, #imageAspects.fade, #imageResources.fade, #imageBuilding.fade {
	visibility: hidden;
	opacity: 0;
	-webkit-transition: all 0.5s ease-in-out 0s;
    -moz-transition: all 0.5s ease-in-out 0s;
    -ms-transition: all 0.5s ease-in-out 0s;
    transition: all 0.5s ease-in-out 0s;
}