*, *::after, *::before {
	box-sizing: border-box;
}

.tooltip {
	position: relative;
	display: inline-block;
	border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
	visibility: hidden;
	width: 260px;
	background-color: black;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 5px 5px;

	/* Position the tooltip */
	position: absolute;
	z-index: 1;

	/* Font style */
	font-family: 'Sofia Pro', sans-serif !important;
	font-size: 20px !important;
	font-weight: 400;
	line-height: 32px;
	letter-spacing: 0.02em;
}

.tooltip:hover .tooltiptext {
	visibility: visible;
	transition-delay: 40ms;
}

.text-center {
	text-align: center;
}

.video-responsive {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;
}
.video-responsive iframe,
.video-responsive object,
.video-responsive embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

@media only screen and (max-width: 992px) {
	.scroll-dot {
		display: none;
	}
	.pt-120-0 {
		padding-top: 0px;
	}
}

.pt-120-0 {
	padding-top: 120px;
}

.scroll-dot {
	position: fixed;
	top: 45%;
	right: 30px;
	transform: translateY(-50%);
	z-index: 10;
	height: 350px;
}

.scroll-dot ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

.scroll-dot ul li {
	width: 356px;
	position: relative;
	text-align: right;
	font-weight: 400;
}

.scroll-dot ul li:nth-child(2) {
	top: 12.5%;
	position: absolute;
}

.scroll-dot ul li:nth-child(3) {
	top: 25%;
	position: absolute;
}

.scroll-dot ul li:nth-child(4) {
	top: 37.5%;
	position: absolute;
}

.scroll-dot ul li:nth-child(5) {
	top: 50%;
	position: absolute;
}

.scroll-dot ul li:nth-child(6) {
	top: 62.5%;
	position: absolute;
}

.scroll-dot ul li:nth-child(7) {
	top: 75%;
	position: absolute;
}

.scroll-dot ul li:nth-child(8) {
	top: 87.5%;
	position: absolute;
}

.scroll-dot ul li:nth-child(9) {
	top: 100%;
	position: absolute;
}

.scroll-dot ul li:nth-child(10) {
	top: 112.5%;
	position: absolute;
}

.scroll-dot ul li .dot {
	color: #fff;
	display: block;
}

.scroll-dot ul li .dot span {
	display: none;
	background: #2973dd;
	letter-spacing: 1px;
	text-transform: uppercase;
	padding: 10px 25px;
	margin-right: 30px;
	border-radius: 3px;
	transform: translateX(30px);
	opacity: 0;
  font-size: 18px;
}

.scroll-dot.resource-dot ul li .dot span {
	background: #B9161C;
}

.scroll-dot.building-dot ul li .dot span {
	background: #d51779;
}

.scroll-dot.aspect-dot ul li .dot span {
	background: #7C861A;
}

.scroll-dot ul li:hover .dot span,
.scroll-dot ul li:hover .dot.active span {
	transform: translateY(-42px);
	opacity: 1;
	display: inline-block;
}

.scroll-dot ul li .dot.active span {
	opacity: 0;
}

.scroll-dot ul li .dot span::before {
	content: '';
	position: absolute;
	top: 50%;
	right: 0;
	transform: translate(7px, -50%);
	border-left: 7px solid #2973dd;
	border-top: 7px solid transparent;
	border-bottom: 7px solid transparent;
	display: block;
}

.scroll-dot.resource-dot ul li .dot span::before {
	border-left: 7px solid #B9161C;
}

.scroll-dot.building-dot ul li .dot span::before {
	border-left: 7px solid #d51779;
}

.scroll-dot.aspect-dot ul li .dot span::before {
	border-left: 7px solid #7C861A;
}

.scroll-dot ul li .dot::before,
.scroll-dot ul li .dot::after {
	content: '';
	position: absolute;
	top: 0%;
	border-radius: 50%;
	display: block;
	transition: 0.2s ease-out;
}

.scroll-dot ul li .dot::before {
	height: 12px;
	width: 12px;
	background: #d7e6fc;
	right: 0;
	transform: translateY(-40%);
}

.scroll-dot.resource-dot ul li .dot::before {
  background: #FCD7D8;
}

.scroll-dot.building-dot ul li .dot::before {
  background: #FCD7EA;
}

.scroll-dot.aspect-dot ul li .dot::before {
  background: #E8EDB2;
}


.scroll-dot ul li .dot.active::before,
.scroll-dot ul li:hover .dot::before {
	border-color: #2973dd;
	background: #2973dd;
}

.scroll-dot.resource-dot ul li .dot.active::before,
.scroll-dot.resource-dot ul li:hover .dot::before {
	border-color: #B9161C;
	background: #B9161C;
}

.scroll-dot.building-dot ul li .dot.active::before,
.scroll-dot.building-dot ul li:hover .dot::before {
	border-color: #d51779;
	background: #d51779;
}

.scroll-dot.aspect-dot ul li .dot.active::before,
.scroll-dot.aspect-dot ul li:hover .dot::before {
	border-color: #7C861A;
	background: #7C861A;
}

.scroll-dot ul li .dot.active::after,
.scroll-dot ul li:hover .dot::after {
	transform: translateY(-50%) scale(1);
}

.scroll-dot ul li .dot::after {
	background-color: #2973dd;
	right: -5px;
	transform: translateY(-50%) scale(0);
}

.scroll-dot.resource-dot ul li .dot::after {
	background-color: #B9161C;
}

.scroll-dot.building-dot ul li .dot::after {
	background-color: #d51779;
}

.scroll-dot.aspect-dot ul li .dot::after {
	background-color: #7C861A;
}
