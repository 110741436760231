.ui.menu .item:before {
	background: none !important;
}

.header.ui.menu a.item:hover {
	background: transparent !important;
	transition: 200ms ease cubic-bezier(0, 0.25, 0.5, 1);
}

.ui.menu {
	border: none !important;
	box-shadow: none !important;
	padding: 0 100px !important;
}

.navigation-container {
	padding-top: 84px;
	padding-bottom: 50px;
	overflow: auto; 
}

.navigation-container2 {
	padding-top: 84px;
	padding-bottom: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100vw;
	height: 100vh;
	overflow-y: scroll;
}

@media only screen and (min-width: 768px) {
	.navigation-container {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100vw;
		height: 100vh;
	}
}

.navigation ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

.navigation {
	color: white !important;
	position: absolute;
	opacity: 1;
	z-index: 11;
	background: #141b44;
	left: -100%;
	transition: all 0.5s ease;
	left: 0;
	top: 0;
	width: 100%;
}

.menu-navigation {
	color: white !important;
	position: absolute;
	opacity: 1;
	z-index: 11;
	background: #141b44;
	left: -100%;
	transition: all 0.5s ease;
	top: 0;
	width: 100%;
}

.menu-navigation-animation {
	color: white !important;
	position: fixed;
	opacity: 1;
	z-index: 11;
	background: #141b44;
	transition: all 0.3s ease;
	left: 0;
	width: 100%;
	top: 0;
}
@media only screen and (min-width: 768px) {
	.close.icon {
		right: 113px !important;
	}
}

.close.icon {
	cursor: pointer;
	position: absolute;
	top: 43px;
	right: 25px;
	z-index: 1;
	opacity: 0.8;
	font-size: 2em;
	color: #fff !important;
	width: 2.25rem;
	height: 2.25rem;
	padding: 0.625rem 0 0 0;
}

i.icon {
	display: inline-block;
	opacity: 1;
	margin: 0 0.25rem 0 0;
	width: 1.18em;
	height: 1em;
	font-family: Icons;
	font-style: normal;
	font-weight: 400;
	text-decoration: inherit;
	text-align: center;
	backface-visibility: hidden;
}

.registry .context-menu:hover,
.registry .color-inherit:hover {
	color: #aac7f2 !important;
	transition: 200ms ease cubic-bezier(0, 0.25, 0.5, 1);
}

.resources .context-menu:hover,
.resources .color-inherit:hover {
	color: #ff9a9d !important;
	transition: 200ms ease cubic-bezier(0, 0.25, 0.5, 1);
}

.aspects .context-menu:hover,
.aspects .color-inherit:hover {
	color: #dde58a !important;
	transition: 200ms ease cubic-bezier(0, 0.25, 0.5, 1);
}

.building .context-menu:hover,
.building .color-inherit:hover {
	color: #e58ab9 !important;
	transition: 200ms ease cubic-bezier(0, 0.25, 0.5, 1);
}

.registry-menu a  {
	color: #fff; 
}

.registry-menu:hover a {
	color: #AAC7F2;
	transition: all 200ms ease-in-out;
	transition-delay: 40ms;
}

.registry-header-link:hover, .registry-first-link:hover, .registry-second-link:hover, .registry-third-link:hover, .registry-fourth-link:hover, .registry-fifth-link:hover, .registry-sixth-link:hover, .registry-seventh-link:hover {
	color: #D7E6FC !important; 
	transition: all 200ms ease-in-out;
	transition-delay: 40ms;
}

.resources-menu a {
	color: #fff; 
}

.resources-menu:hover a {
	color: #F48387;
	transition: all 200ms ease-in-out;
	transition-delay: 40ms;
}

.resources-header-link:hover, .resources-first-link:hover, .resources-second-link:hover, .resources-third-link:hover {
	color: #FCD7D8 !important; 
	transition: all 200ms ease-in-out;
	transition-delay: 40ms;
}

.aspects-menu a {
	color: #fff; 
}

.aspects-menu:hover a {
	color: #DDE58A;
	transition: all 200ms ease-in-out;
	transition-delay: 40ms;
}

.aspects-header-link:hover, .aspects-first-link:hover, .aspects-second-link:hover, .aspects-third-link:hover, .aspects-fourth-link:hover, .aspects-fifth-link:hover, .aspects-sixth-link:hover, .aspects-seventh-link:hover, .aspects-eighth-link:hover, .aspects-ninth-link:hover {
	color: #E8EDB2 !important; 
	transition: all 200ms ease-in-out;
	transition-delay: 40ms;
}

.building-menu a {
	color: #fff; 
}

.building-menu:hover a {
	color: #E58AB9;
	transition: all 200ms ease-in-out;
	transition-delay: 40ms;
}

.building-header-link:hover, .building-first-link:hover, .building-second-link:hover {
	color: #FCD7EA !important; 
	transition: all 200ms ease-in-out;
	transition-delay: 40ms;
}

.inside-menu-navigation {
	font-size: 20px;
	font-style: normal;
	font-weight: 800;
	line-height: 32px;
	text-align: left;
	min-width: 1224px;
}

.menu-navigation .context-menu {
	font-weight: 400;
}

.color-inherit {
	color: inherit;
}

.hideMenu {
	display: none !important;
}

.absoluteMenuTop {
	position: static;
	top: 0;
}

.showShadow {
	box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 5px 0px;
}

.container-icons {
	position: relative;
	top: 12px;
}

.icon-header .icon-header-hover {
	visibility: hidden;
	top: -24px;
}

.icon-header:hover .icon-header-default {
	visibility: visible;
}

.icon-header:hover .icon-header-hover {
	display: block;
	visibility: visible;
	opacity: 1;
	animation: fade 200ms;
}

@keyframes fade {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

#navbar {
	position: fixed;
	top: 0;
	z-index: 1;
	width: 100vw;
	transition: top 0.3s;
	transition-delay: 0.4s;
}

.navbar-short-menu {
	position: relative !important;
}

.title-page {
	color: white;
}

@media only screen and (max-width: 500px) {
	.inside-menu-navigation {
		min-width: auto;
	}
	.menu-navigation-animation {
		max-height: 100vh !important;
		overflow-y: auto !important;
	}
	.registry-menu:hover a {
		color: #FFF;
	}
	.resources-menu:hover a {
		color: #FFF;
	}
	.aspects-menu:hover a {
		color: #FFF;
	}
	.building-menu:hover a {
		color: #FFF;
	}
	.registry-first-link, .registry-second-link, .registry-third-link, .registry-fourth-link, .registry-fifth-link, .registry-sixth-link, .registry-seventh-link {
		color: #AAC7F2 !important;
	}	
	.resources-header-link, .resources-first-link, .resources-second-link, .resources-third-link {
		color: #F48387 !important;
	}
	.aspects-header-link, .aspects-first-link, .aspects-second-link, .aspects-third-link, .aspects-fourth-link, .aspects-fifth-link, .aspects-sixth-link, .aspects-seventh-link, .aspects-eighth-link, .aspects-ninth-link {
		color: #DDE58A !important;
	}
	.building-header-link, .building-first-link, .building-second-link {
		color: #E58AB9 !important;
	}
}

/* @media only screen and (min-width: 1441px) {
  #mt80-0 {
    margin-top: 80px !important;
  }
}  */

@media only screen and (min-width: 501px) and (max-width: 1440px) {
	/* #mt80-0 {
    margin-top: 120px !important;
  } */
	.mt-100-0 {
		margin-top: 70px !important;
	}
	.mt-50-0 {
		margin-top: 20px !important;
	}
}

@media only screen and (min-width: 501px) and (max-width: 1224px) {
	.menu-two-column {
		min-width: auto !important;
	}
}

@media only screen and (max-width: 767px) {
	.menu-navigation-animation {
		max-height: 100vh !important;
		overflow-y: auto !important;
	}
	.resources-menu {
		margin-top: 20px !important;
	}
}

