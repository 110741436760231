.ul-two-column {
	column-count: 2;
}
.search.icon {
	color: white;
	font-size: 3rem;
}

.search.icon:hover {
	cursor: pointer !important;
	pointer-events: all !important;
	transition: 200ms ease cubic-bezier(0, 0.25, 0.5, 1);
}

.ui.input.focus > input,
.ui.input > input:focus,
.ui.input.down input,
.ui.input > input:active,
.ui.input > input {
	border: none !important;
	border-bottom: 1px solid #a3a8cc !important;
	font-size: 60px !important;
	line-height: 60px;
	letter-spacing: -0.01em;
}

.search-box {
	border-left: none; 
	border-top: none; 
	border-right: none; 
	border-bottom: 1px solid #a3a8cc;
	margin-bottom: 40px; 
}

.search-box:focus-visible {
	outline: none;
}

.search-results ul li {
	margin-bottom: 8px !important; 
}

.search-results a {
	color: #A3A8CC;
}

.search-results a:hover {
	color: #fff; 
}

.search-icon {
	/* vertical-align: unset !important;  */
	/* padding-bottom: 50px; */
	vertical-align: top !important;
	padding-bottom: 22px; 
}

#smaller-search2 {
	padding-right: 0px !important; 
	font-family: 'Sofia Pro', sans-serif !important;
	font-weight: 500 !important;
	font-size: 60px !important;
	line-height: 60px !important;
	letter-spacing: -0.01em;
	color: white !important; 
	padding-top: 0px !important;
}

.search-text::placeholder {
	color: #A3A8CC !important;
}

@media only screen and (max-width: 519px) {
	.smaller-search1 {
		width: 345px !important;
	}
	#smaller-search2 {
		font-size: 24px !important;
		line-height: 24px !important;
	}
	.search-icon {
		width: 24px; 
		padding-bottom: 13px; 
	}
	.ul-two-column {
		column-count: 1;
	}
}